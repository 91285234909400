

.h-full
    height: 100%

.static-page-layout
    width: 100%
    height: 100%
    margin-top: 80px;

.no-data-layout
    margin-top: 150px
    padding: 0 2rem
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.link
    color: $color-bnn
    cursor: pointer

.no-data
  position block
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  flex-direction: column
.desc-no-data
  width: 100%
.text-desc-no-data
    font-size: 1.875rem
@media screen and (max-width: 768px)
    .text-desc-no-data
        font-size: 1rem
